import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Chip from '@mui/material/Chip';
import { yellow } from "@mui/material/colors";

import { degrees } from "pdf-lib";

import * as PdfjsLib from "pdfjs-dist";
import { SignAuthorizationStyles } from "../../index.css";
import AddSign from "../addSign";
import DraggableBox from "../draglableBox";
import { saveTramitANFInfo } from "../../../../../store/formANFTramit";
import { randomRgba, verifiVariable } from "../../../../../utils/enums";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import HeaderAddSignInfoANF from "./header";
import { theme } from "../../../../../theme/theme";
import { verifyDocument } from "../../../../../utils/api";

const ControlsCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    background: props.theme.palette.primary.light,
    marginTop: "11px",
    marginBottom: "20px",

    [props.theme.breakpoints.not('xs')]: {
        padding: "14px 22px"
    },

    [props.theme.breakpoints.only('xs')]: {
        padding: "8px 9px"
    },

    "&:hover": {
        background: props.theme.palette.primary.light,
    },
    "&:disabled": {
        background: props.theme.palette.gray.dark,
        color: props.theme.palette.white.main,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const CustomTextControls = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontWeight: 100,
    marginLeft: "25px",
    marginRight: "25px",
}));

const buildId = (length) => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
};

const PreviewDocument = ({ firmantes, onPhrases }) => {
    const screenSizeHook = ScreenSizeHook();
    const {
        formANFTramit: { document, participants },
    } = useSelector((state) => state.root);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const styles = SignAuthorizationStyles();
    const refCanvas = useRef();
    const refPdfViewer = useRef();
    const [docPdf, setDocPdf] = useState(null);
    const [layerX, setLayerX] = useState(0);
    const [signX, setSignX] = useState(0);
    const [layerY, setLayerY] = useState(0);
    const [signY, setSignY] = useState(0);
    // eslint-disable-next-line
    const [fontSize, setFontSize] = useState(40);
    const [openSigModal, setOpenSigModal] = useState(false);
    const [firmsAsigned, setFirmsAsigned] = useState({});
    const [list, setList] = useState([]);
    const [viewport, setViewport] = useState(null);
    const [laodingVerify, setLaodingVerify] = useState(false);
    const [isRendering, setIsRendering] = useState(false);
    const [phrases, setPhrases] = useState([]);
    const { watch, setValue } = useForm({
        defaultValues: {
            currentPage: 1,
        },
    });

    let currentPage = watch("currentPage");

    const goPrevious = () => {
        if (docPdf === null || currentPage === 1) return;
        setValue("currentPage", (currentPage -= 1));
    };

    const goNext = () => {
        if (docPdf == null || currentPage >= docPdf._pdfInfo.numPages) return;
        setValue("currentPage", (currentPage += 1));
    };

    const handleClick = (event) => {
        const canvas = event.target;
        const rect = canvas.getBoundingClientRect();
        const canvasWidth = canvas.width;

        const getLayerY = screenSizeHook === "xs" ? (rect.height / 2) + 30 : (rect.height / 2);

        setLayerY(getLayerY);
        setLayerX(rect.right - canvasWidth / 2);

        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        const startX = parseFloat(scaleX);
        const startY = parseFloat(scaleY);
        const deltaX = offsetX - startX;
        const deltaY = offsetY - startY;

        const getSignY = screenSizeHook === "xs" ? ((deltaX * scaleX - 2) / viewport.scale) + 30 : (deltaX * scaleX - 2) / viewport.scale;

        setSignX(getSignY);
        setSignY((deltaY * scaleY + 20) / viewport.scale);

    };

    const openModal = (e) => {
        const element = e;

        const nodeName = element["target"]["nodeName"];

        if (!["CANVAS"].includes(nodeName)) return;

        handleClick(e);

        setOpenSigModal(true);
    };

    const boxDelete = (id) => {
        const objectSignet = { ...firmsAsigned };

        delete objectSignet[id];

        setFirmsAsigned(objectSignet);
    };

    const addFirmante = (signer) => {
        if ([null, undefined, ""].includes(signer)) return;

        const parseSigner = JSON.parse(signer);

        setFirmsAsigned((itemList) => {

            return Object.assign({
                ...itemList,
                [buildId(4)]: {
                    numbSigned: parseSigner["sign"] + 1,
                    text: `[[${parseSigner["rol"].includes("Firmante") || parseSigner["rol"].includes("0") ? "s" : "c"}|${parseSigner["sign"]}]]`,
                    num: parseSigner["num"],
                    textTootip: parseSigner["fullName"],
                    rut: parseSigner["rut"],
                    x: signX,
                    y: signY,
                    positionBoxX: layerX,
                    positionBoxY: layerY,
                    size: fontSize,
                    signerLegal: parseSigner["signerLegal"],
                    textColor: parseSigner["textColor"],
                    page: currentPage,
                    signer: parseSigner["rol"].includes("Firmante") || parseSigner["rol"].includes("0")
                        ? true
                        : false,
                    rotate: degrees(0),
                },
            })
        }
        );
    };

    const getPosArray = useCallback(
        (item, rol) => {
            let numPosition = 0;

            [...participants]
                .filter((itemFilter) => itemFilter.rol.includes(rol))
                .forEach((value, index) => {
                    if (item["rut"] === value["rut"]) numPosition = index;
                });

            return numPosition;
        },
        [participants]
    );

    const getPosArrayF = useCallback(
        (item, rol, authNeed) => {
            let numPosition = 0;

            [...firmantes]
                .filter((itemFilter) => itemFilter.rol === rol && itemFilter.authNeed === authNeed)
                .forEach((value, index) => {
                    if (item["rut"] === value["rut"]) numPosition = index;
                });

            return numPosition;
        },
        [firmantes]
    );

    useEffect(() => {
        dispatch(
            saveTramitANFInfo({
                indexe: "firms",
                value: firmsAsigned,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firmsAsigned]);

    useEffect(() => {
        let windowWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            if (window.innerWidth !== windowWidth) {
                setFirmsAsigned({});

                windowWidth = window.innerWidth;
            }
        });
    }, []);

    useEffect(() => {

        if (participants.length === 0) return;

        const arraydata = [...participants].filter((item) => item.rol.includes("Aprobador") || item.rol.includes("Firmante")).map((item) => {
            let numPosition = 0;

            if (item.rol.includes("Aprobador")) {
                numPosition = getPosArray(item, "Aprobador");
            }

            if (item.rol.includes("Firmante")) {
                numPosition = getPosArray(item, "Firmante");
            }

            return Object.assign({ ...item }, { num: numPosition + 1 });
        });

        setList(arraydata);
        setFirmsAsigned({});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants]);

    useEffect(() => {

        if (verifiVariable(firmantes)) return;

        const arraydata = [...firmantes].filter((item) => item.rol === "0").map((item) => {
            let numPosition = 0;

            if (item.rol === "0" && item['authNeed'] === "0") {
                numPosition = getPosArrayF(item, "0", "0");
            }

            if (item.rol === "0" && item['authNeed'] === "1") {
                numPosition = getPosArrayF(item, "0", "1");
            }

            return Object.assign({ ...item }, { num: numPosition + 1, rut: item['rutId'], fullName: item['full_name'], textColor: randomRgba() });
        });

        setList(arraydata);
        setFirmsAsigned({});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firmantes]);


    useEffect(() => {
        (async () => {

            if (![null, undefined, ""].includes(document)) {

                setPhrases([]);

                const loadPdfDocumentTask = PdfjsLib.getDocument(document);

                const pdf = await loadPdfDocumentTask.promise;

                setDocPdf(pdf);

                setLaodingVerify(true);

                let doctoVerify = document;

                const restVerifyDoc = await verifyDocument({
                    "pdf_base64": `${doctoVerify.replace("data:application/pdf;base64,", "")}`
                });

                if (!verifiVariable(restVerifyDoc["phrases"])) {
                    setPhrases(restVerifyDoc["phrases"]);
                    onPhrases(restVerifyDoc["phrases"]);
                }

                setLaodingVerify(false);

            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);

    useEffect(() => {
        let isMounted = true;
        const renderPage = async () => {
            if (isRendering || !isMounted) return;
            setIsRendering(true);

            const parsetCurrentPage = parseInt(currentPage);

            let zoomScale = 0;

            if (
                [null, undefined, ""].includes(docPdf) ||
                [0, null, undefined].includes(parsetCurrentPage) ||
                parsetCurrentPage > docPdf._pdfInfo.numPages
            ) {
                setIsRendering(false);
                return;
            }

            let page = await docPdf.getPage(parsetCurrentPage);

            let canvas = refCanvas.current;
            let ctx = canvas.getContext("2d");

            const getViewport = page.getViewport({ scale: .75 });

            const isLandscape = getViewport.width > getViewport.height;

            if (['lg', 'xl'].includes(screenSizeHook)) {

                let scaleLg = .80;

                if (isLandscape === true) scaleLg = .60;

                zoomScale = scaleLg;
            }

            if (['md', 'sm', 'xs'].includes(screenSizeHook)) {

                let scaleMd = .45;

                if (isLandscape === true) scaleMd = .35;

                zoomScale = scaleMd;

            }

            let viewport = page.getViewport({ scale: zoomScale });

            if ([viewport.width, viewport.height].includes(0)) {
                setIsRendering(false);
                return;
            }

            setViewport(viewport);

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            await page.render({
                canvasContext: ctx,
                viewport: viewport,
            }).promise;

            setIsRendering(false);

            window["jQuery"](refPdfViewer.current)
                .width(viewport.width)
                .height(viewport.height);
        }

        renderPage();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdf, currentPage, screenSizeHook]);

    return (
        <Box className={`${styles.contentDocuments}`}>
            <AddSign
                key={"flkafkje"}
                openSigModal={openSigModal}
                setOpenSigModal={(e) => setOpenSigModal(e)}
                signatory={(e) => addFirmante(e)}
                signatories={list}
            />

            <Box sx={{ marginBottom: '1rem' }}>
                <HeaderAddSignInfoANF />
            </Box>

            <div
                style={{
                    backgroundColor: theme.palette.primary.xxlight
                }}
                className={styles.pdfViewer}
                // onClick={(e) => openModal(e)}
                ref={refPdfViewer}
            >
                {!verifiVariable(docPdf) &&
                    Object.keys({ ...firmsAsigned }).map((item) => {
                        return (
                            firmsAsigned[item]["page"] === currentPage && (
                                <DraggableBox
                                    key={item}
                                    positions={(element) =>
                                        setFirmsAsigned((itemList) =>
                                            Object.assign(
                                                { ...itemList },
                                                { [item]: element }
                                            )
                                        )
                                    }
                                    deleteBox={() => boxDelete(item)}
                                    refCanvas={refCanvas}
                                    signatory={firmsAsigned[item]}
                                    viewport={viewport}
                                />
                            )
                        );
                    })}

                {
                    laodingVerify === true &&
                    (
                        <>
                            <CircularProgress size="5rem" />

                            <Typography
                                variant="h5"
                                gutterBottom
                                fontFamily={"PoppinsBold"}
                                mt={7}
                            >
                                Analizando documento
                            </Typography>

                        </>
                    )
                }

                {
                    phrases.length > 0 &&
                    <>
                        <WarningIcon sx={{ fontSize: 80, color: yellow[600] }} />

                        <Typography
                            color={theme.palette.primary.main}
                            gutterBottom
                            textAlign={"center"}
                            fontFamily={"PoppinsBold"}
                        >
                            Este trámite es presencial. Envíanos el documento a <a href="mailto:contacto@firmavirtual.legal">contacto@firmavirtual.legal</a> y te ayudaremos con notaría a domicilio.
                        </Typography>

                        <Typography
                            textAlign={"center"}
                            color={theme.palette.primary.main}
                            gutterBottom
                            mt={3}
                        >
                            Frases encontradas:
                        </Typography>

                        {phrases.map((item, index) => <Chip key={index} sx={{ backgroundColor: yellow[600], margin: .5 }} icon={<ErrorIcon sx={{ fill: "white" }} />} label={item} />)}

                    </>

                }

                <canvas
                    style={{ display: [laodingVerify, phrases.length > 0].includes(true) ? "none" : "initial" }}
                    onClick={(e) => openModal(e)}
                    id="pdf_renderer"
                    ref={refCanvas}
                />
            </div>

            <div id="navigation_controls" className={styles.navigationControls}>
                <ControlsCustomButtom
                    style={{
                        marginRight: isMobile ? "15px" : "0px",
                    }}
                    disabled={currentPage === 1}
                    onClick={() => goPrevious()}
                    variant="contained"
                    disableElevation
                >
                    {isMobile ? "Anterior" : "Página Anterior"}
                </ControlsCustomButtom>

                {!["xs", "sm", "md"].includes(screenSizeHook) && (
                    <CustomTextControls variant="h6" gutterBottom>
                        {currentPage}-{docPdf ? docPdf._pdfInfo.numPages : 0}
                    </CustomTextControls>
                )}

                <ControlsCustomButtom
                    disabled={
                        [null, undefined, ""].includes(docPdf)
                            ? false
                            : currentPage === docPdf._pdfInfo.numPages
                    }
                    onClick={() => goNext()}
                    variant="contained"
                    disableElevation
                >
                    {isMobile ? "Siguiente" : "Página Siguiente"}
                </ControlsCustomButtom>
            </div>
        </Box >
    );
};

export default PreviewDocument;
